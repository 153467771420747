import React from 'react';
import { Progress, ProgressProps } from 'antd';

import translate from 'common/utils/translate';
import { numberWithCommas } from 'admin/utils/number-with-commas';
import Text from 'admin/components/common/Text';
import { formattedValue } from 'admin/components/pages/Settings/Billing/Plans/AIUsage';
import { useAIUsage } from 'admin/components/pages/Settings/Billing/Plans/use-ai-usage';
import { useWidgetUsage } from 'admin/components/pages/Settings/Billing/Plans/use-widget-usage';
import type { IUser, IUserOrganization } from 'src/types';

import { formatNumber } from 'src/common/utils/format-number';
import { FloatingBox } from './FloatingBox';
import css from './Usage.scss';

const t = (p: string, ...args: any[]) => translate(`user.${p}`, ...args);

type Props = {
	user: IUser;
	onMouseEnter?: () => void;
};

export function getUsage({ plan, pageviews = 0 }: IUserOrganization) {
	return {
		pageviews: {
			current: pageviews,
			max: plan?.maxPageviews,
		},
	};
}

const Usage = (props: Props) => {
	const [isDetailsShown, setDetailsShown] = React.useState(false);
	const detailsPopupTimeoutRef = React.useRef<number | null>(null);
	const { organization } = props.user;
	const aiUsage = useAIUsage();
	const [getWidgetUsage, widgetUsage] = useWidgetUsage({ lazy: true });

	React.useEffect(() => {
		if (isDetailsShown) {
			getWidgetUsage();
		}
	}, [isDetailsShown, getWidgetUsage]);

	if (!organization) return null;

	const { pageviews } = getUsage(organization);
	const pageViewsProgressProps: ProgressProps = {
		percent: pageviews.max ? (pageviews.current / pageviews.max) * 100 : 0,
		size: 5,
		strokeColor: 'var(--ra-color-error-ds)',
		showInfo: false,
	};
	const aiUsageProgressProps: ProgressProps = {
		percent: aiUsage?.max ? ((aiUsage?.formattedData.total ?? 0) / aiUsage.max) * 100 : 0,
		size: 5,
		strokeColor: 'var(--ra-color-primary-400)',
		showInfo: false,
	};
	const widgetUsageProgressProps: ProgressProps = {
		percent: widgetUsage?.max ? ((widgetUsage.views ?? 0) / widgetUsage.max) * 100 : 0,
		size: 5,
		strokeColor: 'var(--ra-color-primary-400)',
		showInfo: false,
	};

	const isUnlimitedPageviews = pageviews.max === undefined || pageviews.max === null;
	const shouldRenderPageviews =
		!isUnlimitedPageviews && pageviews.max! > 0 ? pageviews.current / pageviews.max! > 0.1 : true;
	const isUnlimitedWidgetPageviews = widgetUsage.max === undefined || widgetUsage.max === null;

	const onMouseEnter = () => {
		if (detailsPopupTimeoutRef.current) {
			window.clearTimeout(detailsPopupTimeoutRef.current);
		}

		setDetailsShown(true);
	};

	const onMouseLeave = () => {
		detailsPopupTimeoutRef.current = window.setTimeout(() => {
			setDetailsShown(false);
		}, 300);
	};

	return (
		<div className={css.usage} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<Text className={css.name} weight="semibold" size="label" compact text={organization?.name} />
			{aiUsage.isFetched && (
				<>
					{shouldRenderPageviews && (
						<div className={css.usageBarWrapper}>
							{!isUnlimitedPageviews && <Progress className={css.progress} {...pageViewsProgressProps} />}
							<Text size="footnote">
								{isUnlimitedPageviews ? formatNumber(pageviews.current) : ''} {t('pageviews')}
							</Text>
						</div>
					)}
					{aiUsage.formattedData && (
						<>
							<div className={css.usageBarWrapper}>
								<Progress className={css.progress} {...aiUsageProgressProps} />
								<Text size="footnote">{t('aiUsage')}</Text>
							</div>
							{isDetailsShown && (
								<FloatingBox className={css.details}>
									<div className={css.detailsSection}>
										<Text size="description">{t('pageviews')}</Text>
										<Progress className={css.progress} {...pageViewsProgressProps} />
										<Text className={css.views} size="footnote">
											{t('pageviewsCount', {
												current: numberWithCommas(pageviews.current),
												max: isUnlimitedPageviews
													? 'Unlimited'
													: numberWithCommas(pageviews.max),
											})}
										</Text>
									</div>
									<div className={css.detailsSection}>
										<Text size="description">{t('aiUsage')}</Text>
										<Progress className={css.progress} {...aiUsageProgressProps} />
										<Text className={css.views} size="footnote">
											{t('pageviewsCount', {
												current: formattedValue(aiUsage.formattedData.total),
												max:
													typeof aiUsage.max === 'number'
														? formattedValue(aiUsage.max, 'USD')
														: 'Unlimited',
											})}
										</Text>
									</div>

									<div className={css.detailsSection}>
										<Text size="description">{t('widgetViews')}</Text>
										<Progress className={css.progress} {...widgetUsageProgressProps} />
										<Text className={css.views} size="footnote">
											{t('pageviewsCount', {
												current: numberWithCommas(widgetUsage.views ?? 0),
												max: isUnlimitedWidgetPageviews
													? 'Unlimited'
													: numberWithCommas(widgetUsage.max),
											})}
										</Text>
									</div>
								</FloatingBox>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default Usage;
