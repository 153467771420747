import type { CmsCollectionType, CmsCollectionDataItem, CmsModel } from 'types/cms';
import type { AsyncThunkActionReturnType } from 'types/redux-utils';
import { cms as cmsEndpoints } from 'admin/resources';
import { createThunkAction } from 'admin/actions/helpers';
import { selectActiveOrganizationId } from 'admin/reducers/user/selectors';
import { GET_COLLECTIONS, GET_COLLECTION_ITEMS, GET_COLLECTIONS_AND_ITEMS } from 'admin/constants/actions';

// Collections types
type GetCollectionsResponse = ApiV2Response<CmsCollectionType[]>;
type GetCollectionItemsResponse = { collectionId: string; items: CmsCollectionDataItem[] };
type GetCollectionsAndItemsResponse = ApiV2Response<CmsModel>;

// Action Types
export type GetCollectionsAction =
	| { type: typeof GET_COLLECTIONS.PENDING; payload: void }
	| { type: typeof GET_COLLECTIONS.FULFILLED; payload: AsyncThunkActionReturnType<typeof getCollections> }
	| { type: typeof GET_COLLECTIONS.REJECTED; payload: string };

export type GetCollectionItemsAction =
	| { type: typeof GET_COLLECTION_ITEMS.PENDING; payload: void }
	| { type: typeof GET_COLLECTION_ITEMS.FULFILLED; payload: AsyncThunkActionReturnType<typeof getCollectionItems> }
	| { type: typeof GET_COLLECTION_ITEMS.REJECTED; payload: string };

export type GetCollectionsAndItemsAction =
	| { type: typeof GET_COLLECTIONS_AND_ITEMS.PENDING; payload: void }
	| {
			type: typeof GET_COLLECTIONS_AND_ITEMS.FULFILLED;
			payload: AsyncThunkActionReturnType<typeof getCollectionsAndItems>;
	  }
	| { type: typeof GET_COLLECTIONS_AND_ITEMS.REJECTED; payload: string };

// Thunk actions

// Fetch Collections
export const getCollections = createThunkAction<GetCollectionsResponse['body'], void>({
	type: GET_COLLECTIONS,
	payloadCreator: async (_, { getState }) => {
		const organizationId = selectActiveOrganizationId(getState());
		const response = await cmsEndpoints.get.collections.params({ organizationId }).send<GetCollectionsResponse>();
		return { success: true, result: response.body ?? [] };
	},
});

// Fetch Collection Items
export const getCollectionItems = createThunkAction<
	GetCollectionItemsResponse,
	{ collectionId: string; limit?: Nullable<number> }
>({
	type: GET_COLLECTION_ITEMS,
	payloadCreator: async ({ collectionId, limit }) => {
		const response = await cmsEndpoints.get.collectionItems
			.params({ collectionId })
			.send<ApiV2Response<CmsCollectionDataItem[]>>();

		// todo: replace `slice` with query param
		return { success: true, result: { collectionId, items: (response.body ?? []).slice(0, limit ?? Infinity) } };
	},
});

// Fetch Collections and Items in one action
export const getCollectionsAndItems = createThunkAction<
	GetCollectionsAndItemsResponse['body'],
	{ collections: 'all' | string[]; items: { id: string; limit: Nullable<number> }[] }
>({
	type: GET_COLLECTIONS_AND_ITEMS,
	payloadCreator: async (params, { getState }) => {
		const collections: CmsCollectionType[] = [];

		// Fetch collections
		if (params.collections === 'all') {
			const organizationId = selectActiveOrganizationId(getState());
			const collectionsResponse = await cmsEndpoints.get.collections
				.params({ organizationId })
				.send<GetCollectionsResponse>();
			collections.push(...(collectionsResponse.body ?? []));
		} else {
			const collectionsResponse = await Promise.all(
				params.collections.map(async collectionId => {
					try {
						const response = await cmsEndpoints.get.collection
							.params({ collectionId })
							.send<ApiV2Response<CmsCollectionType>>();
						return response.body;
					} catch {
						return null;
					}
				})
			);
			collections.push(...collectionsResponse.filter((c): c is CmsCollectionType => c !== null));
		}

		// Fetch items
		let items: { [collectionId: string]: CmsCollectionDataItem[] } | null = null;
		if (params.items.length) {
			const itemsResponse = await Promise.all(
				params.items.map(async ({ id: collectionId, limit }) => {
					try {
						const response = await cmsEndpoints.get.collectionItems
							.params({ collectionId })
							.send<ApiV2Response<CmsCollectionDataItem[]>>();

						// todo: replace `slice` with query param
						return { collectionId, items: (response.body ?? []).slice(0, limit ?? Infinity) };
					} catch {
						return { collectionId, items: [] };
					}
				})
			);

			items = itemsResponse.reduce(
				(acc, data) => {
					acc[data.collectionId] = data.items;
					return acc;
				},
				{} as Record<string, CmsCollectionDataItem[]>
			);
		}

		return {
			success: true,
			result: {
				collections,
				items,
			},
		};
	},
});
