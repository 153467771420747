import type { EditableStateInfo, StorySettingsOfCard } from 'types/story';
import type { SCFontItem } from 'types/fonts';
import { templateString } from 'utils/template-string';

export { IS_DEVEL } from 'utils/environment';
export const ADMIN_SESSION = 'adminSessionId';
export const CLIENT_SESSION = 'sessionId';

export const CARD_TYPE = {
	INFO: 'INFO',
	TRIVIA: 'TRIVIA',
	SORTABLE_TRIVIA: 'SORTABLE_TRIVIA',
	SORTABLE_POLL: 'SORTABLE_POLL',
	POLL: 'POLL',
	PERSONALITY_TEST: 'PERSONALITY_TEST',
	TRUE_OR_FALSE: 'TRUE_OR_FALSE',
	THIS_OR_THAT: 'THIS_OR_THAT',
	NAVIGATION: 'NAVIGATION',
	FORM: 'FORM',
	REGISTRATION: 'REGISTRATION',
	SANDBOX: 'SANDBOX',
} as const;

export const PARAMS = {
	ORGANIZATION_ID: 'organizationId',
	DOMAIN_ID: 'domainId',
	STORY_ID: 'storyId',
	CARD_ID: 'cardId',
	TEAM_ID: 'teamId',
	MEMBER_ID: 'memberId',
	TEMPLATE_ID: 'templateId',
	PROVIDER: 'provider',
	REDIRECT: 'redirect', // '0' | undefined
	WEBHOOK_ID: 'webhookId',
	WEBHOOK_LOG_ID: 'webhookLogId',
	GENERATOR_ID: 'generatorId',
	COLLECTION_ID: 'collectionId',
} as const;

export const COMPONENT_TYPE = {
	TEXT: 'TEXT',
	IMG: 'IMG',
	ANSWER: 'ANSWER',
	ANSWER_PROGRESS: 'ANSWER_PROGRESS',
	FLOAT_ABOVE: 'FLOAT_ABOVE',
	FLOAT_BELOW: 'FLOAT_BELOW',
	CONTENT: 'CONTENT',
	CARD: 'CARD',
	BUTTON: 'BUTTON',
	BUTTON_SUBMIT: 'BUTTON_SUBMIT',
	GOOGLE_LOGIN: 'GOOGLE_LOGIN',
	SMS_LOGIN: 'SMS_LOGIN',
	BOX: 'BOX',
	SHAPE: 'SHAPE',
	EMBED: 'EMBED',
	TEXT_FIELD: 'TEXT_FIELD',
	TEXT_AREA_FIELD: 'TEXT_AREA_FIELD',
	CHECKBOX: 'CHECKBOX',
	SHARE: 'SHARE',
	RESULT_TEXT: 'RESULT_TEXT',
	RESULT_SHAPE: 'RESULT_SHAPE',
	SWIPE: 'SWIPE',
	VIDEO: 'VIDEO',
	TIMER: 'TIMER',
	LOTTIE: 'LOTTIE',
	SELECT: 'SELECT',
	OVERLAY: 'OVERLAY',
	SORTABLE_BOX: 'SORTABLE_BOX',
	COUNTER: 'COUNTER',
	SLIDER: 'SLIDER',
	SLIDER_SLIDES: 'SLIDER_SLIDES',
	SLIDER_ARROW: 'SLIDER_ARROW',
	SLIDER_PAGINATION: 'SLIDER_PAGINATION',
} as const;

export const COMPONENT_DISPLAY_NAME: Record<keyof typeof COMPONENT_TYPE, string> = {
	TEXT: 'Text',
	IMG: 'Image',
	ANSWER: 'Answer',
	ANSWER_PROGRESS: 'AnswerProgress',
	FLOAT_ABOVE: 'Float above',
	FLOAT_BELOW: 'Float below',
	CONTENT: 'Content',
	CARD: 'Card',
	BUTTON: 'Button',
	BUTTON_SUBMIT: 'Submit',
	GOOGLE_LOGIN: 'Google login',
	SMS_LOGIN: 'SMS login',
	BOX: 'Box',
	SHAPE: 'Shape',
	EMBED: 'Embed',
	TEXT_FIELD: 'TextField',
	TEXT_AREA_FIELD: 'TextAreaField',
	CHECKBOX: 'Checkbox',
	SHARE: 'Share',
	RESULT_TEXT: 'ResultText',
	RESULT_SHAPE: 'ResultShape',
	SWIPE: 'Swipe',
	VIDEO: 'Video',
	TIMER: 'Timer',
	LOTTIE: 'Lottie',
	SELECT: 'Select',
	OVERLAY: 'Overlay',
	SORTABLE_BOX: 'SortableBox',
	COUNTER: 'Counter',
	SLIDER: 'Slider',
	SLIDER_ARROW: 'Arrow',
	SLIDER_PAGINATION: 'Pagination',
	SLIDER_SLIDES: 'Slides container',
};

export const STORY_SETTINGS_COMPONENTS = {
	[COMPONENT_TYPE.CARD]: {
		path: templateString('cards.{{ cardId }}'),
	},
	[COMPONENT_TYPE.ANSWER]: {
		path: templateString('cards.{{ cardId }}.answers.{{ answerId }}'),
	},
};

export const CARD_ROOT_ELEMENT_INDEX = {
	[COMPONENT_TYPE.FLOAT_ABOVE]: 0,
	[COMPONENT_TYPE.CONTENT]: 1,
	[COMPONENT_TYPE.FLOAT_BELOW]: 2,
	[COMPONENT_TYPE.CARD]: 3,
} as const;

export const STORY_ROOT_ELEMENT_INDEX = {
	[COMPONENT_TYPE.FLOAT_ABOVE]: 0,
	[COMPONENT_TYPE.FLOAT_BELOW]: 1,
};

export const LAYER_TYPE = {
	// this layer adds block to story.elements and leave his original ref in card element without render.
	// Also wraps it with a fixed positioned container
	FIXED_GLOBAL: 0,
	FIXED: 1, // this layer wraps block with a fixed positioned container
	FLOAT: 2, // this layer moves block to one of the float layers
	CONTENT: undefined, // this layer moves block to "content" container at card elements
} as const;

export const IFRAME_ACTIONS = {
	GET_STORY: '@IFRAME/GET_STORY',
	UPDATE_ADMIN_PREVIEW_LOCATION: '@IFRAME/UPDATE_ADMIN_LOCATION',
	UPDATE_CARD_ELEMENT_BY_PATH: '@IFRAME/UPDATE_CARD_ELEMENT_BY_PATH',
	UPDATE_STORY_ELEMENT_BY_PATH: '@IFRAME/UPDATE_STORY_ELEMENT_BY_PATH',
	SET_EDITABLE_EDITOR_ELEMENT: '@IFRAME/SET_EDITABLE_EDITOR_ELEMENT',
	INITIATE_SELECT_EDITOR_ELEMENT: '@IFRAME/INITIATE_SELECT_EDITOR_ELEMENT',
	APPLY_SCROLLBAR_FIX: '@IFRAME/APPLY_SCROLLBAR_FIX',
	UPDATE_CLIENT_CARD_DATA: '@EDITOR/UPDATE_CLIENT_CARD_DATA',
	UPDATE_CLIENT_STORY_ELEMENTS: '@EDITOR/UPDATE_CLIENT_STORY_ELEMENTS',
};

export const DEFAULT_MEDIA_QUERY_PLATFORMS = {
	DEFAULT: 'default',
	FULL_HD: 'fullHD',
	DESKTOP: 'desktop',
	TABLET: 'tablet',
	MOBILE_LANDSCAPE: 'mobileLandscape',
	MOBILE: 'mobile',
} as const;

export const MQ_SIZES_TEXT_MAP = {
	[DEFAULT_MEDIA_QUERY_PLATFORMS.FULL_HD]: 'createStoryModal.platform.full_hd.mq',
	[DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP]: 'createStoryModal.platform.desktop.mq',
	[DEFAULT_MEDIA_QUERY_PLATFORMS.TABLET]: 'createStoryModal.platform.tablet.mq',
	[DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE_LANDSCAPE]: 'createStoryModal.platform.mobile_landscape.mq',
	[DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE]: 'createStoryModal.platform.mobile.mq',
};

export const DEFAULT_MEDIA_QUERY_SIZES = {
	[DEFAULT_MEDIA_QUERY_PLATFORMS.FULL_HD]: { min: 1920, ratio: 1920 / 1080 },
	[DEFAULT_MEDIA_QUERY_PLATFORMS.DESKTOP]: { min: 1280, ratio: 1280 / 800 },
	[DEFAULT_MEDIA_QUERY_PLATFORMS.TABLET]: { min: 768, ratio: 1024 / 768 },
	[DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE_LANDSCAPE]: { min: 479, ratio: 667 / 375 },
	[DEFAULT_MEDIA_QUERY_PLATFORMS.MOBILE]: { min: null, ratio: 375 / 667 },
};

// Supported states by app
export const COMPONENT_STATES = {
	DEFAULT: 'defaultState',
	// [data-selected-state]
	SELECTED: 'selectedState',
	// [data-hover-state]
	HOVER: 'hoverState',
	// [data-selected-hover-state]
	SELECTED_HOVER: 'selectedHoverState',
	// [data-correct-state]
	CORRECT: 'correctState',
	// [data-incorrect-state]
	INCORRECT: 'incorrectState',
} as const;

// List of states in order of assignment
export const COMPONENT_STATES_ORDERED = [
	COMPONENT_STATES.DEFAULT,
	COMPONENT_STATES.SELECTED,
	COMPONENT_STATES.HOVER,
	COMPONENT_STATES.SELECTED_HOVER,
	COMPONENT_STATES.CORRECT,
	COMPONENT_STATES.INCORRECT,
];

// List of states used by particular building blocks
export const COMPONENT_STATES_LIST = {
	[COMPONENT_TYPE.ANSWER]: [
		COMPONENT_STATES.DEFAULT,
		COMPONENT_STATES.SELECTED,
		COMPONENT_STATES.CORRECT,
		COMPONENT_STATES.INCORRECT,
		COMPONENT_STATES.HOVER,
	],
	[COMPONENT_TYPE.BUTTON]: [COMPONENT_STATES.DEFAULT, COMPONENT_STATES.HOVER],
	[COMPONENT_TYPE.BUTTON_SUBMIT]: [COMPONENT_STATES.DEFAULT, COMPONENT_STATES.HOVER],
	[COMPONENT_TYPE.IMG]: [COMPONENT_STATES.DEFAULT, COMPONENT_STATES.HOVER],
	[COMPONENT_TYPE.SHAPE]: [COMPONENT_STATES.DEFAULT, COMPONENT_STATES.HOVER],
	[COMPONENT_TYPE.TEXT_FIELD]: [COMPONENT_STATES.DEFAULT, COMPONENT_STATES.INCORRECT],
	[COMPONENT_TYPE.TEXT_AREA_FIELD]: [COMPONENT_STATES.DEFAULT, COMPONENT_STATES.INCORRECT],
	[COMPONENT_TYPE.CHECKBOX]: [
		COMPONENT_STATES.DEFAULT,
		COMPONENT_STATES.HOVER,
		COMPONENT_STATES.SELECTED,
		COMPONENT_STATES.INCORRECT,
	],
	[COMPONENT_TYPE.SHARE]: [COMPONENT_STATES.DEFAULT, COMPONENT_STATES.HOVER],
	[COMPONENT_TYPE.SWIPE]: [COMPONENT_STATES.DEFAULT, COMPONENT_STATES.SELECTED],
};

export const SCORE = {
	DEFAULT: 5, // 5 is a default value at backend,
	MIN: 0, // total score min
	MAX: 100, // total score max
	RANGE: {
		MIN: 0, // total score min
		MAX: 100, // total score max
		MIN_RANGE_SIZE: 10, // min range size
	},
};

export const NUM_SELECTED_ANSWERS = {
	MIN: 1,
	MAX: 10,
};

export const FLOW_DEFAULT_EVENT_NAME = 'Default';

// Flow event connection type
export const CONNECTION_TYPES = {
	NEXT: 'NEXT', // bind to 1st card in next step
	ANY: 'ANY', // bind to point by end point id (card)
	STEP: 'STEP', // bind to point by end point id (step) (random card in step)
} as const;

export enum Pin {
	l = 'l',
	t = 't',
	r = 'r',
	b = 'b',
	c = 'c',
	lt = 'lt',
	lb = 'lb',
	rb = 'rb',
	rt = 'rt',
	none = '',
}
export const PIN_DEFAULT = Pin.t;

export const IMG_TINT_ATTR = 'data-img-tint';

export const CONTENT_ALIGN_Y = {
	top: 'top',
	center: 'center',
	bottom: 'bottom',
} as const;

export const TRANSITION_TYPE = {
	NONE: 'none',
	FADE: 'fade',
	ZOOM: 'zoom',
} as const;

export const EXPOSE_DEFAULT_DURATION = 0; // seconds

export enum ANIMATION_EFFECT_ID {
	BG_COLOR = 'BG_COLOR',
	COLOR = 'COLOR',
	OPACITY = 'OPACITY',
	X = 'X',
	Y = 'Y',
	ROTATE = 'ROTATE',
	SCALE = 'SCALE',
	SCALE_X = 'SCALE_X',
	SCALE_Y = 'SCALE_Y',
}

export const ANIMATION_STATE = {
	NONE: 'NONE',
	NOT_STARTED: 'NOT_STARTED',
	PLAYING: 'PLAYING',
	COMPLETE: 'COMPLETE',
} as const;

// Animation triggers that are handled by EvenEmitter (see `const AnimationEvEm`)
const ANIMATION_EVENT_EMITTER_TRIGGER = {
	ON_CARD_ANSWER: 'onCardAnswer',
	ON_CARD_ANSWER_CORRECT: 'onCardAnswerCorrect',
	ON_CARD_ANSWER_INCORRECT: 'onCardAnswerIncorrect',
	ON_SUBMIT: 'onSubmit',
};

// List of all available animation triggers
export const ANIMATION_TRIGGER = {
	ON_LOAD: 'onLoad',
	ON_CARD_EXPOSE_START: 'onCardExposeStart',
	ON_CARD_EXPOSE_COMPLETE: 'onCardExposeComplete',
	ON_VIEWPORT: 'onViewport',
	ON_HOVER: 'onMouseEnter',
	ON_CLICK: 'onClick',
	...ANIMATION_EVENT_EMITTER_TRIGGER,
} as const;

export const ANIMATION_KEYFRAMES = {
	INITIAL: 'initial',
	FINAL: 'final',
} as const;

export const DEFAULT_EDITABLE_STATE_INFO: EditableStateInfo = {
	state: COMPONENT_STATES.DEFAULT,
	source: {
		id: '',
		path: '',
		type: '',
		layer: LAYER_TYPE.CONTENT,
	},
} as const;

export const DISABLED_ANSWER_ATTR = 'data-disabled-answer';

type CardSettingsKeys = keyof StorySettingsOfCard;
interface StorySettingsPropI {
	showResults: Extract<CardSettingsKeys, 'showResults'>;
	showCorrect: Extract<CardSettingsKeys, 'showCorrect'>;
	score: Extract<CardSettingsKeys, 'score'>;
	countType: Extract<CardSettingsKeys, 'countType'>;
	answersMinMax: Extract<CardSettingsKeys, 'answersMinMax'>;
	forceSubmitBtn: Extract<CardSettingsKeys, 'forceSubmitBtn'>;
	sortableType: Extract<CardSettingsKeys, 'sortableType'>;
}
export const STORY_SETTING_PROP: StorySettingsPropI = {
	showResults: 'showResults',
	showCorrect: 'showCorrect',
	score: 'score',
	countType: 'countType',
	answersMinMax: 'answersMinMax',
	forceSubmitBtn: 'forceSubmitBtn',
	sortableType: 'sortableType',
};

// alias for "StorySettingsType.cards.<cardId>.answers.<answerId>.characterPoints.<characterId>: number"
export const characterPoints = 'characterPoints';

export const ELEMENT_EDIT_MODE = {
	INSTANCE: 'INSTANCE', // edit current element (default)
	MASTER: 'MASTER', // edit master symbol linked to current element (Symbols only)
} as const;

export const CARD_DEFAULT_TEMPLATE_NAME = 'DEFAULT';

export const STORY_TYPE = {
	STANDALONE: 'standalone',
	EMBED: 'embed',
	WIDGET: 'widget',
} as const;

export const SYSTEM_FONTS: Record<string, Pick<SCFontItem, 'url' | 'fontType' | 'fontFamily'>> = {
	VARELA_ROUND: {
		fontFamily: '"Varela Round", sans-serif',
		fontType: 'google',
		url: 'Varela+Round',
	},
	MIRIAM_LIBRE: {
		fontFamily: '"Miriam Libre", sans-serif',
		fontType: 'google',
		url: 'Miriam+Libre:wght@400;700',
	},
	SUEZ_ONE: {
		fontFamily: '"Suez One", serif',
		fontType: 'google',
		url: 'Suez+One',
	},
	SECULAR_ONE: {
		fontFamily: '"Secular One", sans-serif',
		fontType: 'google',
		url: 'Secular+One',
	},
	ALEF: {
		fontFamily: 'Alef, sans-serif',
		fontType: 'google',
		url: 'Alef:wght@400;700',
	},
	RUBIK: {
		fontFamily: 'Rubik, sans-serif',
		fontType: 'google',
		url: 'Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900',
	},
};

export const GPT_AD_DEFAULT_CONTAINER_ID = 'gpt-ad-container';

export const GPT_AD_DEFAULT_CLOSING_FN = 'onStoryAdvClose';

export const AI_TEXT_ADJUSTMENT_OPTIONS = [
	{ value: '', label: 'Neutral' },
	// Often used in business, academic, or professional contexts where a formal and respectful tone is required
	{ value: 'Make it more formal.', label: 'Formal' },
	// Useful for making complex or technical information accessible to a general audience or for ensuring clarity.
	{ value: 'Make it simpler and clearer.', label: 'Friendly' },
	// Desired in marketing or social media to make the content more engaging and relatable to the audience
	{ value: 'Add a humorous touch', label: 'Humor' },
	// Important in sales, advertising, or argumentative writing where the goal is to convince or influence the reader.
	{ value: 'Give it a persuasive edge.', label: 'Persuasive' },
	// Used in industry-specific writing, where a high level of professionalism and use of jargon is expected.
	{ value: 'Make it sound professional and technical.', label: 'Intellectual' },
];

export const STORY_VERSIONS = {
	latest: 'latest',
	published: 'published',
	archived: 'archived',
	unpublished: 'unpublished',
} as const;
