import configureStore, { history } from 'common/configure-store';
import { autoSyncMiddleware } from 'admin/middleware/auto-sync-middleware';
import stateSyncMiddleware from 'admin/middleware/state-sync-middleware';
import { AUTO_SYNC_TARGET } from 'admin/constants/common';
import multiTabStorySync from 'admin/components/pages/Story/MultiTabSync/state-middleware-config';
import reducer, { AdminReducerState } from './reducers';

const middleware = () => [
	/* sync story between different browsing context(tab,window,etc.) of the same origin */
	stateSyncMiddleware(multiTabStorySync),
	/* auto save changes that made in story/card editor */
	autoSyncMiddleware({
		[AUTO_SYNC_TARGET.CARD_EDITOR]: 2500,
		[AUTO_SYNC_TARGET.STORY_EDITOR]: 2500,
	}),
];

export { history };

const adminStore = configureStore({} as AdminReducerState, reducer, { middleware, id: 'adminStore' });

export default adminStore;
