interface GlobalCacheConfig {
	storageType: 'localStorage' | 'memory';
	// Cache duration in ms
	cacheDuration?: number;
	disableCache?: boolean;
}

export interface EndpointCacheConfig extends Omit<GlobalCacheConfig, 'storageType'> {
	cacheKey?: string;
	/**
	 * @example [ApiCache.generateCacheKey(`/item/:${ITEM_ID}`, { customCacheKey: 'customCacheKey' })]
	 * @example [(params) => ApiCache.generateCacheKey(`story/:${STORY_ID}`, { [STORY_ID]: params[STORY_ID] })]
	 */
	clearCacheKeys?: (string | ((params: any) => string))[];
}

type CacheEntry<T> = {
	timestamp: number;
	data: T;
};

export class ApiCache {
	private static globalConfig: GlobalCacheConfig = {
		storageType: 'memory',
		cacheDuration: 1000 * 60 * 5,
		disableCache: false,
	};

	private static memoryCache: Map<string, CacheEntry<any>> = new Map();

	static initialize(config: GlobalCacheConfig) {
		this.globalConfig = { ...this.globalConfig, ...config };
	}

	static generateCacheKey(url: string, params: any): string {
		return `${url}:${JSON.stringify(params)}`;
	}

	private static getMemoryCache<T>(key: string): T | null {
		const entry = this.memoryCache.get(key);
		if (!entry) return null;

		const now = Date.now();
		if (now - entry.timestamp > (this.globalConfig.cacheDuration || 0)) {
			this.memoryCache.delete(key);
			return null;
		}

		return entry.data;
	}

	private static setMemoryCache<T>(key: string, data: T): void {
		const entry: CacheEntry<T> = {
			timestamp: Date.now(),
			data,
		};
		this.memoryCache.set(key, entry);
	}

	static getCache<T>(key: string, config?: EndpointCacheConfig): T | null {
		const finalConfig = { ...this.globalConfig, ...config };
		if (finalConfig.disableCache) {
			return null;
		}

		if (finalConfig.storageType === 'localStorage') {
			const entryJson = localStorage.getItem(key);
			if (!entryJson) return null;

			const entry: CacheEntry<T> = JSON.parse(entryJson);
			const now = Date.now();
			if (now - entry.timestamp > (finalConfig.cacheDuration || 0)) {
				localStorage.removeItem(key);
				return null;
			}

			return entry.data;
		}

		return this.getMemoryCache<T>(key);
	}

	static setCache<T>(key: string, data: T, config?: EndpointCacheConfig): void {
		const finalConfig = { ...this.globalConfig, ...config };
		if (finalConfig.disableCache) {
			return;
		}

		if (finalConfig.storageType === 'localStorage') {
			const entry: CacheEntry<T> = {
				timestamp: Date.now(),
				data,
			};
			localStorage.setItem(key, JSON.stringify(entry));
		} else {
			this.setMemoryCache<T>(key, data);
		}
	}

	static clearCache(keys: string[]): void {
		keys.forEach(key => {
			this.memoryCache.delete(key);
			localStorage.removeItem(key);
		});
	}
}
