import React from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import store from 'admin/store';
import { Provider } from 'react-redux';
import { OAUTH, ROOT } from 'admin/constants/routes';
import { BrowserRouter, Route } from 'react-router-dom';
import RouterSwitch from 'admin/components/common/RouterSwitch';
import ErrorBoundary from 'admin/components/common/ErrorBoundary';
import Debugger from 'common/components/Debugger';
import OAuth from 'admin/components/pages/OAuth';
import { translator } from 'utils/translate';
import themeBase from 'admin/styles/_export.scss';

import App from './App';

translator.source = 'admin';

function getConfirmation(message, callback) {
	const allowTransition = window.confirm(message); // eslint-disable-line no-alert
	callback(allowTransition);
}

const theme: ThemeConfig = {
	token: {
		colorPrimary: themeBase.baseColorBrand,
		colorText: themeBase.baseTextColor,
		fontFamily: themeBase.baseFontFamily,
		fontSize: 14,
		lineHeight: parseFloat(themeBase.baseLineHeight),
		borderRadius: parseInt(themeBase.baseBorderRadius, 10),
		borderRadiusOuter: parseInt(themeBase.baseBorderRadius, 10),
		borderRadiusXS: parseInt(themeBase.baseBorderRadius, 10),
	},
	components: {
		Slider: {
			// ...
		},
	},
};

const Root = () => {
	return (
		<Provider store={store}>
			<ConfigProvider theme={theme}>
				<ErrorBoundary>
					<BrowserRouter getUserConfirmation={getConfirmation}>
						<RouterSwitch>
							<Route path={OAUTH} component={OAuth} />
							<Route path={ROOT} component={App} />
						</RouterSwitch>
					</BrowserRouter>
					<Debugger />
				</ErrorBoundary>
			</ConfigProvider>
		</Provider>
	);
};

export default Root;
