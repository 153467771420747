import { entries } from 'lodash';
import type {
	BBStates,
	StoryMediaPlatform,
	BBModel,
	WithStateAndPlatform,
	BBOtherProp,
	BBStylesProp,
} from 'types/story';
import { UNIFORM_PROPS } from 'common/constants/component-props';

type ParseComponentPropsCallback = (params: {
	prop: string;
	value: unknown;
	state?: BBStates;
	platform?: StoryMediaPlatform;
}) => void;

/**
 * Parse `uiConfig.componentProps` and `children: BBModelTextChild` of the component
 */
export function parseComponentProps(component: BBModel, callback: ParseComponentPropsCallback) {
	Object.keys(component.uiConfig.componentProps).forEach(prop => {
		// parse uniform prop separately, because it doesn't have state or platform, it's not an object
		if (prop in UNIFORM_PROPS) {
			callback({ prop, value: component.uiConfig.componentProps[prop] });
		} else if (prop === 'other' || prop === 'styles') {
			type Other = WithStateAndPlatform<BBOtherProp>;
			type Styles = WithStateAndPlatform<BBStylesProp>;
			const stylesOrOther = component.uiConfig.componentProps[prop] as Other | Styles;

			entries(stylesOrOther).forEach(([state, platforms]) => {
				entries(platforms).forEach(([platform, values]) => {
					entries(values).forEach(([key, value]) => {
						callback({
							prop: key,
							value,
							state: state as BBStates,
							platform: platform as StoryMediaPlatform,
						});
					});
				});
			});
		}
	});

	if (component.children && !Array.isArray(component.children)) {
		entries(component.children).forEach(([state, platforms]) => {
			entries(platforms).forEach(([platform, value]) => {
				callback({
					prop: 'children',
					value,
					state: state as BBStates,
					platform: platform as StoryMediaPlatform,
				});
			});
		});
	}
}
