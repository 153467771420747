import React from 'react';
import { floor } from 'lodash';
import { Progress } from 'antd';
import cn from 'classnames';

import Text from 'admin/components/common/Text';

import css from './Limits.scss';

type FormatType = 'fraction' | 'percentage' | 'value';

type Props<F extends FormatType = FormatType> = {
	title: string;
	format: F extends FormatType ? FormatType : FormatType;
	current: F extends 'value' ? string | number : number;
	max?: number | null;
	className?: string;
	dataClassName?: string;
	progress?: boolean;
	progressColor?: 'progress' | keyof typeof color;
	withPadding?: boolean;
	titleSize?: keyof typeof Text.size;
	titleWeight?: keyof typeof Text.weight;
	valueSize?: keyof typeof Text.size;
};

const color = {
	danger: 'var(--ra-color-error-ds)',
	warning: '#E5AC73',
	neutral: 'rgba(0, 0, 0, 0.38)',
};

const infinityCharacter = '∞';

function getProgressColor(percent: number) {
	if (percent >= 90) return color.danger;
	if (percent >= 50) return color.warning;
	return color.neutral;
}

function getValueDisplay({ current, max, format }: Pick<Props, 'current' | 'max' | 'format'>) {
	// @ts-expect-error todo: infer props.current properly
	if (format === 'percentage' && typeof max === 'number') return `${floor((current / max) * 100, 1)}%`;
	if ((format === 'percentage' || format === 'fraction') && max === null) return `${current}/${infinityCharacter}`;
	return format === 'fraction' && typeof max === 'number' ? `${current}/${max}` : current;
}

const Limits = <F extends FormatType>({
	title,
	max,
	current,
	format,
	withPadding = false,
	titleSize = 'subheading',
	titleWeight = 'semibold',
	valueSize,
	progress = false,
	progressColor = 'danger',
	className,
	dataClassName,
}: Props<F>) => {
	const percent = typeof current === 'number' && typeof max === 'number' && max > 0 ? (current / max) * 100 : 0;

	return (
		<div className={cn(css.limits, className)}>
			<div className={cn(css.data, dataClassName, { [css.withPadding]: withPadding })}>
				<Text text={title} size={titleSize} weight={titleWeight} compact />
				<Text size={valueSize || titleSize} className={css.count} compact>
					{getValueDisplay({ current, max, format })}
				</Text>
			</div>
			{progress ? (
				<Progress
					className={css.progress}
					percent={percent}
					size={5}
					strokeColor={progressColor === 'progress' ? getProgressColor(percent) : color[progressColor]}
					showInfo={false}
				/>
			) : null}
		</div>
	);
};

export default Limits;
