import { useEffect } from 'react';
import { transmitTo, IFRAME_ACTIONS } from 'utils/iframe-tunnel';
import { useWindowSize } from 'common/components/useWindowSize';
import { useResponsiveContext } from 'client/components/common/ResponsiveProvider/Context';

export const scrollFix = {
	target: document.documentElement,
	attr: 'data-scroll',
	check() {
		return this.target.getAttribute(this.attr) === 'fixed';
	},
	set() {
		return this.target.setAttribute(this.attr, 'fixed');
	},
};

/**
 * Check whether or not scrollbar included into viewport.
 * In some environments scrollbar is a part of viewport in other no, because of this
 * it is important to increase iframe width by a scrollbar width to avoid media queries mismatch.
 *
 * Current component is developed to use only for a stories rendered in iframe by admin panel and
 * with provided controls to change platform
 */

const ScrollbarFix = () => {
	const windowSize = useWindowSize({ debounce: 300 });
	const { boundaries } = useResponsiveContext();

	useEffect(
		function fix() {
			/*
			On platform change in admin, window width is already applied to client iframe,
			but updated state from <ResponsiveProvider> at client can be still not provided.
			Do not call `matchMedia` until `boundaries` value isn't updated.
			 */
			const { innerWidth } = window;
			const isOldPlatform = innerWidth > boundaries.max || innerWidth < boundaries.min;
			if (isOldPlatform) return;

			const scrollbarWidth = innerWidth - document.body.clientWidth;
			const query = `(min-width: ${boundaries.min}px) and (max-width: ${boundaries.max}px)`;
			const isMatch = window.matchMedia(query).matches;

			if (isMatch) return;

			const frameWidth = `calc(100% + ${scrollbarWidth}px)`;
			const frameSelector = `iframe[src$="${window.location.pathname}${window.location.search}"]`;

			scrollFix.set();

			transmitTo({
				id: 'ScrollbarFix',
				target: 'admin',
				action: IFRAME_ACTIONS.APPLY_SCROLLBAR_FIX,
				payload: {
					frameWidth,
					frameSelector,
				},
			});
		},
		[windowSize, boundaries.min, boundaries.max]
	);

	return null;
};

export default ScrollbarFix;
