import React, { useState } from 'react';
import cn from 'classnames';
import { forEach, get } from 'lodash';
import { CardFacade } from 'utils/facades/card-facade';
import { StoryFacade } from 'utils/facades/story-facade';
import { CountType } from 'utils/facades/navigation-card-facade';
import { PersonalityTestFacade } from 'utils/facades/personality-test-card-facade';
import { characterPoints } from 'common/constants';
import Text from 'admin/components/common/Text';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { Modal } from 'admin/components/common/Modal';
import { postGallery } from 'admin/actions/story/gallery';
import { Column, Grid } from 'admin/components/common/Grid';
import type { ModalManagerProvidedProps } from 'admin/components/common/ModalManager';
import type { InspectorTypes } from 'admin/components/pages/Story/CardEditor/Inspector/types';
import { UPDATE_TARGET } from 'admin/components/pages/Story/CardEditor/Inspector/constants';
import { selectEditableCard } from 'admin/reducers/card-editor/selectors';
import { selectEditableStory } from 'admin/reducers/story-editor/selectors';
import { MODAL } from 'admin/constants/common';
import GeneralView from './GeneralView';
import SeoView from './SeoView';
import ShareView from './ShareView';
import Form, { Props as FormProps, Values } from './Form';
import { menu, names, parseScore, score, text, VIEW } from './utils';
import css from './CardSettingsModal.scss';

export type CardSettingsModalData = { onUpdate: InspectorTypes.OnUpdate; defaultView?: VIEW };

type ModalProps = ModalManagerProvidedProps<MODAL.CARD_SETTINGS>;

const viewMap = {
	[VIEW.GENERAL]: GeneralView,
	[VIEW.SHARE]: ShareView,
	[VIEW.SEO]: SeoView,
};

const CardSettings: React.FC<Omit<ModalProps, 'open'>> = props => {
	const editableCard = useAdminSelector(selectEditableCard)!;
	const editableStory = useAdminSelector(selectEditableStory)!;
	const dispatch = useAdminDispatch();

	const { data } = props;
	const cardFacade = new CardFacade(editableCard);
	const storyFacade = new StoryFacade(editableStory);
	const hasAnswerScore = cardFacade.hasFeature.answerScore;

	const [view, setView] = useState<VIEW>(props.data.defaultView || VIEW.GENERAL);
	const [error, setError] = useState(false);

	const onSubmit = async (values: Values) => {
		const fieldNames = Object.keys(names) as Array<keyof typeof names>;
		const payload = fieldNames.reduce(
			(acc, key) => {
				const name = names[key];
				const pathToValue = typeof name === 'string' ? name : name(cardFacade.cardId);
				let value = get(values, pathToValue);

				if (key === score) {
					value = parseScore(value);
				}

				acc.name.push(pathToValue);
				acc.value.push(value);
				return acc;
			},
			{
				name: [] as string[],
				value: [] as (string | number | undefined)[],
				updateTarget: UPDATE_TARGET.STORY_SETTINGS,
			}
		);

		const answers = hasAnswerScore && values.settings.cards?.[cardFacade.cardId]?.answers;
		if (answers && cardFacade.card instanceof PersonalityTestFacade) {
			// use facade, but not value for get countType to support old stories
			const ptCountType = cardFacade.card.getCountType(values.settings);
			const answersPath = `settings.cards.${cardFacade.cardId}.answers`;

			forEach(answers, (answerSettings, answerId) => {
				if (ptCountType === CountType.score && characterPoints in answerSettings) {
					payload.name.push(`${answersPath}.${answerId}.${characterPoints}`);
					payload.value.push(''); // delete
				} else if (ptCountType === CountType.character && score in answerSettings) {
					payload.name.push(`${answersPath}.${answerId}.${score}`);
					payload.value.push(''); // delete
				}
			});
		}

		// add to payload updated score for all cards
		if (values._setScoreForAll) {
			const scoreValue = parseScore(get(values, names[score](cardFacade.cardId)));
			storyFacade.cards.forEach(card => {
				const _cardFacade = new CardFacade(card);
				if (_cardFacade.hasFeature.score && _cardFacade.cardId !== cardFacade.cardId) {
					payload.name.push(names[score](_cardFacade.cardId));
					payload.value.push(scoreValue);
				}
			});
		}

		await data.onUpdate(payload);

		props.close();
	};

	const renderFormContent = (formProps: FormProps) => {
		const Content = viewMap[view];
		return (
			<div className={css.content}>
				<Content
					card={cardFacade}
					postGallery={params => dispatch(postGallery(params))}
					storyId={storyFacade.storyId}
					formChange={formProps.change}
				/>

				<div className={css.footer}>
					<Column justifyContent="flex-start">
						<Button
							type="submit"
							view="primary"
							textSize="label"
							className={css.button}
							disabled={formProps.pristine || formProps.invalid}
						>
							{text('save')}
						</Button>
						<Button onClick={props.close} textSize="label" view="secondary-gray" className={css.button}>
							{text('discard')}
						</Button>
					</Column>
				</div>
			</div>
		);
	};

	return (
		<div className={css.inner}>
			<Grid className={css.grid} columns="12" gap="large">
				{/* Aside */}
				<Column colSpan="3" className={css.col1}>
					<div className={css.menu}>
						{menu.map(item => (
							<button
								key={`card-settings-${item.label}`}
								disabled={error}
								type="button"
								className={cn(css.menuItem, { [css.active]: item.view === view })}
								onClick={error ? undefined : () => setView(item.view)}
							>
								<Text size="subheading" text={item.label} />
							</button>
						))}
					</div>
				</Column>

				{/* Content */}
				<Column colSpan="9" className={css.col2}>
					<div className={css.header}>
						<Text size="subtitle" weight="semibold" compact text={text('title')} />
						<Text
							size="footnote"
							compact
							text={CardFacade.getCardNameByType(editableCard.type)}
							className={css.cardType}
						/>
					</div>
					<Form onSubmit={onSubmit} setError={setError} cardId={cardFacade.cardId} cardType={cardFacade.type}>
						{renderFormContent}
					</Form>
				</Column>
			</Grid>
		</div>
	);
};

const CardSettingsModal = ({ open, ...props }: ModalProps) => {
	return (
		<Modal
			maskColor="black"
			theme="dark"
			open={open}
			destroyOnClose
			width={parseInt(css.modalWidth, 10)}
			className={css.modal}
			transitionName=""
		>
			{/* modal will unmount its content on close, but won't destroy itself */}
			<CardSettings {...props} />
		</Modal>
	);
};

export default CardSettingsModal;
