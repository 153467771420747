import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StoryTemplateWithoutData } from 'types/story';
import { debounce } from 'lodash';
import { useAdminSelector, useAdminDispatch } from 'admin/reducers';
import Button from 'admin/components/common/Button';
import { CARD_TYPE } from 'common/constants';
import Loader from 'common/components/Loader';
import Text from 'admin/components/common/Text';
import { Search } from 'admin/components/common/Search';
import { GET_STORY_TEMPLATES } from 'admin/constants/actions';
import { selectLoadingStateByName } from 'admin/reducers/loading/selectors';
import { selectActiveOrganization } from 'admin/reducers/user/selectors';
import { getStoryTemplates, GetStoryTemplatesParams } from 'admin/actions/story/templates';
import Layout from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/Layout';
import GalleryItem from 'admin/components/pages/Stories/CreateStoryModal/ShareComponents/TemplateItem';
import TemplateGalleryFilters from './TemplateGalleryFilters';
import { CreateStoryFromTemplateParams, FormValues } from './Form';
import { createStoryFormValuesSelector } from './helpers';
import css from './CreateStoryModal.scss';

type Props = {
	language: FormValues['lang'];
	storyType: FormValues['type'];
	onPreviewClick: (templateId: number) => void;
	onOk: (params?: CreateStoryFromTemplateParams) => void;
	// set "true" to hide component nodes, but keep component state
	hidden: boolean;
};

const TemplateGallery: React.FC<Props> = ({ language, storyType, onOk, ...props }) => {
	// app state
	const activeOrganization = useAdminSelector(selectActiveOrganization)!;
	const isLoading = useAdminSelector(state => selectLoadingStateByName(state, GET_STORY_TEMPLATES.PENDING));
	const formValues = useAdminSelector(createStoryFormValuesSelector) as FormValues;

	// component state
	const dispatch = useAdminDispatch();
	const [templates, setTemplates] = useState<StoryTemplateWithoutData[]>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [requestPage] = useState(0);
	const [cardTypes, setCardTypes] = useState<string[]>(formValues.type === 'widget' ? [CARD_TYPE.INFO] : []);
	const [includeGlobalTemplates, setIncludeGlobalTemplates] = useState(false);
	const [organizationId, setOrganizationId] = useState('');
	const isInitialized = useRef(templates.length > 0);

	const debouncedGetTemplates = useMemo(() => {
		return debounce(async (params: GetStoryTemplatesParams) => {
			const result = await dispatch(getStoryTemplates(params));
			if (result.success) setTemplates((result.result ?? []).filter(o => !!o.story?.published));
		}, 300);
	}, [dispatch]);

	useEffect(() => {
		debouncedGetTemplates({
			page: requestPage,
			includeGlobalTemplates,
			language,
			storyType,
			...(cardTypes.length ? { cardTypes: encodeURIComponent(cardTypes.join(',')) } : null),
			...(searchTerm ? { term: searchTerm } : null),
			...(organizationId ? { organizationId } : null),
		});

		if (!isInitialized.current) {
			debouncedGetTemplates.flush();
			isInitialized.current = true;
		}
	}, [
		debouncedGetTemplates,
		requestPage,
		cardTypes,
		searchTerm,
		language,
		storyType,
		includeGlobalTemplates,
		organizationId,
	]);

	const onOrganizationFilterChange = useCallback(
		(value: string | null) => {
			switch (value) {
				case activeOrganization.id:
					setOrganizationId(v => (v === value ? '' : value));
					break;
				default:
					setIncludeGlobalTemplates(v => !v);
					break;
			}
		},
		[activeOrganization.id]
	);

	const onCardTypeFilterChange = useCallback(
		(value: string) => {
			const checked = cardTypes.includes(value);
			setCardTypes(prevState => (checked ? prevState.filter(v => v !== value) : [...prevState, value]));
		},
		[cardTypes]
	);

	const organizationFilterOptions = useMemo(
		() =>
			activeOrganization.id === 'storycards'
				? []
				: [
						{
							label: activeOrganization.name,
							value: activeOrganization.id,
							checked: organizationId === activeOrganization.id,
						},
						{ label: 'Storycards', value: null, checked: includeGlobalTemplates },
					],
		[activeOrganization, includeGlobalTemplates, organizationId]
	);

	const onAddClick = useCallback(
		(templateId: number) => {
			return onOk({ template: templateId });
		},
		[onOk]
	);

	if (props.hidden) {
		return null;
	}

	return (
		<>
			<Layout.Panel>
				<Search
					value={searchTerm}
					onSearchChange={e => setSearchTerm(e.target.value)}
					placeholder="Search story templates..."
					className={Layout.className.colTwoThirds}
					onClear={() => setSearchTerm('')}
				/>
			</Layout.Panel>
			<Layout.Aside>
				<TemplateGalleryFilters
					onCardTypeFilterChange={onCardTypeFilterChange}
					onOrganizationFilterChange={onOrganizationFilterChange}
					organizationOptions={organizationFilterOptions}
					cardTypes={cardTypes}
					storyType={formValues.type}
				/>

				<Button smallText onClick={() => onOk()}>
					Start from scratch
				</Button>
			</Layout.Aside>
			<Layout.Content className={Layout.className.pt35}>
				<div className={css.gallery}>
					{templates.map(template => {
						return (
							<GalleryItem
								key={template.id}
								type="story"
								item={template}
								onPreviewClick={props.onPreviewClick}
								onAddClick={onAddClick}
							/>
						);
					})}
				</div>
				{isLoading ? (
					<Loader fullScreen color="currentColor" bgColor="var(--ra-color-white-dark)" size="medium" />
				) : templates.length === 0 && isInitialized.current ? (
					<Text
						size={Text.size.article}
						color={Text.color.blackDark}
						text="No results..."
						style={{ textAlign: 'center' }}
					/>
				) : null}
			</Layout.Content>
		</>
	);
};

export default TemplateGallery;
