import { createSelector } from 'reselect';

import { AdminReducerState } from 'admin/reducers';

export const selectCmsModel = (state: AdminReducerState) => state.cms;

export const selectCollections = createSelector(selectCmsModel, cms => cms.collections);

export const selectItems = createSelector(selectCmsModel, cms => cms.items);

const getCollectionId = (_: AdminReducerState, collectionId: string) => collectionId;

export const selectCollectionItems = createSelector(
	[selectItems, getCollectionId],
	(items, collectionId) => items?.[collectionId]
);
