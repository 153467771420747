import { get } from 'lodash';
import { appLog } from 'utils/helpers';
import type {
	CardData,
	EditableStateInfo,
	SelectedBB,
	StorySettingsType,
	StorySymbols,
	StoryVersionType,
} from 'types/story';
import type { CmsModel } from 'types/cms';
import type { SetStoryPayload } from 'client/actions/set-story';
import type { GenerateTextParams } from 'admin/actions/story/ai/types';
import type { SetEditorModePayload } from 'admin/actions/card-editor/set-editor-mode';
import type { LottiePlaybackPayload } from 'client/components/common/BuildingBlocks/Lottie/utils';
import type { CollectedTreeData } from 'client/components/common/BuildingBlocks/utils/traverse-tree-data-collector';

const CLIENT_IFRAME_ID = 'client-iframe';

function getClientFrame() {
	if (typeof document === 'undefined') {
		console.error('document is undefined');
		return {};
	}
	const frame = document.getElementById(CLIENT_IFRAME_ID) as HTMLIFrameElement;

	return {
		contentDocument: get(frame, 'contentDocument'),
		contentWindow: get(frame, 'contentWindow'),
		frame,
	};
}

function getClientFrameNode(id: string) {
	return getClientFrame().contentDocument?.getElementById(id);
}

function getClientFrameNodeStyles(id: string) {
	const frame = getClientFrame();
	const node = frame.contentDocument?.getElementById(id);
	return node ? frame.contentWindow!.getComputedStyle(node) : ({} as CSSStyleDeclaration);
}

/**
 * Action types for interactions between client app and card editor.
 * Use "@FRAME/" prefix to describe an action sent from client app to editor
 * Use "@EDITOR/" prefix to describe action sent from editor to client app.
 */
const IFRAME_ACTIONS = {
	GET_STORY: '@IFRAME/GET_STORY',
	STORY_RENDER_FAILED: '@IFRAME/STORY_RENDER_FAILED',
	UPDATE_ADMIN_PREVIEW_LOCATION: '@IFRAME/UPDATE_ADMIN_LOCATION',
	UPDATE_CARD_ELEMENT_BY_PATH: '@IFRAME/UPDATE_CARD_ELEMENT_BY_PATH',
	UPDATE_STORY_ELEMENT_BY_PATH: '@IFRAME/UPDATE_STORY_ELEMENT_BY_PATH',
	SET_EDITABLE_EDITOR_ELEMENT: '@IFRAME/SET_EDITABLE_EDITOR_ELEMENT',
	INITIATE_SELECT_EDITOR_ELEMENT: '@IFRAME/INITIATE_SELECT_EDITOR_ELEMENT',
	FORCE_SELECTION_HINT_UPDATE: '@IFRAME/FORCE_SELECTION_HINT_UPDATE',
	APPLY_SCROLLBAR_FIX: '@IFRAME/APPLY_SCROLLBAR_FIX',
	UPLOAD_MEDIA: '@IFRAME/UPLOAD_MEDIA',
	OPEN_AI_MODAL: '@IFRAME/OPEN_AI_MODAL',
	AI_TEXT_ACTION: '@IFRAME/AI_TEXT_ACTION',
	EDIT_IMAGE: '@IFRAME/EDIT_IMAGE',
	CARD_HEIGHT: '@IFRAME/CARD_HEIGHT',
	UPDATE_CLIENT_CARD_DATA: '@EDITOR/UPDATE_CLIENT_CARD_DATA',
	UPDATE_CLIENT_STORY_ELEMENTS: '@EDITOR/UPDATE_CLIENT_STORY_ELEMENTS',
	SET_EDITOR_MODE: '@EDITOR/SET_EDITOR_MODE',
	LOTTIE_PLAYBACK: '@EDITOR/LOTTIE_PLAYBACK',
	GET_TRAVERSE_TREE_DATA: '@EDITOR/GET_TRAVERSE_TREE_DATA',
} as const;

type MessageToAdmin = { target: typeof IframeTunnel.targets.admin } & MessageDataToAdmin;

type MessageToClient = { target: typeof IframeTunnel.targets.client } & MessageDataToClient;

type AdminReceiverMessageEvent = MessageEvent<
	{ storycardsIframeMessage: boolean; target: typeof IframeTunnel.targets.admin } & MessageDataToAdmin
>;

type ClientReceiverMessageEvent = MessageEvent<
	{ storycardsIframeMessage: boolean; target: typeof IframeTunnel.targets.client } & MessageDataToClient
>;

type ReceiverMessageEvent = AdminReceiverMessageEvent | ClientReceiverMessageEvent;

type Props = {
	// main function to handle received secure and filtered messages
	messageReceiver?: (event: ReceiverMessageEvent) => void;
	// invoker id (used for logs only)
	id: string;
	// filter messages by action type
	filter?: string[];
};

class IframeTunnel {
	static targets = {
		admin: 'admin', // transmit to admin
		client: 'client', // transmit to client
	} as const;

	messageReceiver: Props['messageReceiver'];

	id: Props['id'];

	filter?: Props['filter'];

	sourceName: 'CLIENT' | 'ADMIN';

	log: (...args: any[]) => void;

	constructor(props: Props = { id: 'unknown' }) {
		this.sourceName = window.location.pathname.match(/preview.html/g) ? 'CLIENT' : 'ADMIN';
		this.id = `${this.sourceName}:${props.id}`;
		this.filter = props.filter;

		this.log = appLog.extend('IframeTunnel');

		this.messageReceiver = props.messageReceiver;
	}

	_messageReceiver = (event: ReceiverMessageEvent) => {
		const isSecureOrigin = window.location.origin === event.origin;
		const isSecureMessage = event.data.storycardsIframeMessage;

		if (!this.messageReceiver || !isSecureMessage || !isSecureOrigin) {
			return;
		}

		if (this.filter && !this.filter.includes(event.data.action)) {
			return;
		}

		this.log(`%c[GET]  ${this.id} »%c${event.data.action} %O`, 'font-weight: bold;', 'color: #66CDAA;', event);

		this.messageReceiver(event);
	};

	init() {
		this.log(`%c[INIT] ${this.id}`, 'font-weight: bold;');
		window.addEventListener('message', this._messageReceiver);
	}

	destroy() {
		this.log(`%c[DESTROY] ${this.id}`, 'font-weight: bold;');
		window.removeEventListener('message', this._messageReceiver);
	}

	/**
	 * @param props
	 * @param props.action {String}
	 * @param props.payload {Object}
	 * @params props.target {String} OneOf(['client', 'admin']) To where transmit the message
	 */

	messageTransmitter = (props: MessageToClient | MessageToAdmin) => {
		this.log(`%c[POST] ${this.id} » %c${props.action} %O`, 'font-weight: bold;', 'color: #66CDAA;', props);
		const targets = {
			[IframeTunnel.targets.client]: () => getClientFrame().contentWindow,
			[IframeTunnel.targets.admin]: () => window.parent,
		};

		const target = get(props, 'target', IframeTunnel.targets.admin);
		const targetWindow = targets[target]?.();

		if (!targetWindow) {
			this.log(`%c[POST] ${this.id} » targetWindow is NOT FOUND`, 'font-weight: bold;');
			return;
		}

		targetWindow.postMessage(
			{
				target,
				storycardsIframeMessage: true, // key used to drop 3rd party libraries messages
				action: get(props, 'action'),
				payload: get(props, 'payload'),
			} as ReceiverMessageEvent['data'],
			window.location.origin
		);
	};
}

/**
 * postMessage utility function
 */
function transmitTo(props: { id: string } & (MessageToClient | MessageToAdmin)) {
	const { id, target, action, payload } = props;
	const iframeTunnel = new IframeTunnel({ id });
	iframeTunnel.messageTransmitter({ target, action, payload } as MessageToClient | MessageToAdmin);
}

type UpdateElementByPathValue = string | number | boolean | undefined | object;

type MessageDataToAdmin =
	| {
			action: typeof IFRAME_ACTIONS.APPLY_SCROLLBAR_FIX;
			payload: { frameSelector: string; frameWidth: string };
	  }
	| {
			action: typeof IFRAME_ACTIONS.SET_EDITABLE_EDITOR_ELEMENT;
			payload: SelectedBB;
	  }
	| {
			action: typeof IFRAME_ACTIONS.UPDATE_ADMIN_PREVIEW_LOCATION;
			payload: Record<string, string>;
	  }
	| {
			action:
				| typeof IFRAME_ACTIONS.UPDATE_CARD_ELEMENT_BY_PATH
				| typeof IFRAME_ACTIONS.UPDATE_STORY_ELEMENT_BY_PATH;
			payload: { path: string | string[]; value: UpdateElementByPathValue | UpdateElementByPathValue[] };
	  }
	| {
			action: typeof IFRAME_ACTIONS.GET_STORY;
			payload?: { getCollectionsAndItems: boolean };
	  }
	| {
			action: typeof IFRAME_ACTIONS.UPLOAD_MEDIA;
			payload?: never;
	  }
	| {
			action: typeof IFRAME_ACTIONS.OPEN_AI_MODAL;
			payload?: never;
	  }
	| {
			action: typeof IFRAME_ACTIONS.AI_TEXT_ACTION;
			payload: GenerateTextParams;
	  }
	| {
			action: typeof IFRAME_ACTIONS.EDIT_IMAGE;
			payload: boolean;
	  }
	| {
			action: typeof IFRAME_ACTIONS.CARD_HEIGHT;
			payload: { cardHeight: number };
	  }
	| {
			action: typeof IFRAME_ACTIONS.STORY_RENDER_FAILED;
			payload: { error: string };
	  }
	| {
			action: typeof IFRAME_ACTIONS.GET_TRAVERSE_TREE_DATA;
			payload: CollectedTreeData;
	  };

type MessageDataToClient =
	| {
			action: typeof IFRAME_ACTIONS.LOTTIE_PLAYBACK;
			payload: LottiePlaybackPayload;
	  }
	| {
			action: typeof IFRAME_ACTIONS.SET_EDITOR_MODE;
			payload: SetEditorModePayload;
	  }
	| {
			action: typeof IFRAME_ACTIONS.INITIATE_SELECT_EDITOR_ELEMENT;
			payload: {
				editableState: EditableStateInfo;
				id: string;
				scrollIntoView?: boolean;
				forceSelectionId?: string;
				eventOptions?: MouseEventInit;
			};
	  }
	| {
			action: typeof IFRAME_ACTIONS.UPDATE_CLIENT_CARD_DATA;
			payload: {
				editableState: EditableStateInfo;
				data?: CardData;
				settings?: StorySettingsType;
				symbols?: StorySymbols;
				cmsModel?: CmsModel;
			};
	  }
	| {
			action: typeof IFRAME_ACTIONS.UPDATE_CLIENT_STORY_ELEMENTS;
			payload: {
				editableState: EditableStateInfo;
				elements?: StoryVersionType['data']['elements'];
				settings?: StorySettingsType;
				symbols?: StorySymbols;
				cmsModel?: CmsModel;
			};
	  }
	| {
			action: typeof IFRAME_ACTIONS.GET_STORY;
			payload: SetStoryPayload;
	  }
	| { action: typeof IFRAME_ACTIONS.FORCE_SELECTION_HINT_UPDATE; payload: undefined };

export {
	IframeTunnel,
	transmitTo,
	IFRAME_ACTIONS,
	AdminReceiverMessageEvent,
	ClientReceiverMessageEvent,
	UpdateElementByPathValue,
	getClientFrame,
	getClientFrameNode,
	getClientFrameNodeStyles,
	CLIENT_IFRAME_ID,
};
