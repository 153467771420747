import type { CmsModel } from 'types/cms';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { CollectionPaginationType, CollectionPaginationTriggersProp } from 'types/story';

/*
 Implementation concept:

 [Editor]      : connect elements with cms. create references and mark repeatable components
                 display cms data or no?
                 currently displayed data for all particular fields and for repeatable only 1st item

 				 *Required data*: on demand for a sidebar or all integrated collections in case of we display cms data

 [Preview]     : process story in place where data is loaded and modify to generate repeatable components

 				 *Required data*: all integrated collections

 [Pre-publish] : process story in place where data is pushed to server and modify story settings with
                 repeatable components data. Don't need to store generated blocks!
                 Need only to store in settings generated ids for repeatable components
                 and original ids for elements which were used to generate repeatable components. For example:
                 `answers: { [generatedBlockId]: { generatedFor: { dataId, originalBlockId } } }`

 				 *Required data*: length of each collection, to loop through repeatable components, but if there is no
 				 repeatable, then I don't need anything

 [Published]   : process story in place where data is loaded and modify to generate repeatable components,
                 but reuse generated ids from pre-publish if they exist

 				 *Required data*: all integrated collections, to generate repeatable components and display actual data
 */

const DATA_ANY = 'any';
const DELIMITER = '::';
const referencePattern = new RegExp(`^${DELIMITER}([^: ]+)${DELIMITER}([^: ]+)${DELIMITER}([^: ]+)$`);

/**
 * @param collectionId - id of collection
 * @param dataId - id of data row
 * @param dataKey - key of data row object (column name)
 *
 * @example createReference('collectionId', 'dataId', 'username') => '::collectionId::dataId::username'
 */
/**
 * @param {string} collectionId - The unique identifier for the collection.
 * @param {string} dataId       - The unique identifier for an individual item (row) within the collection.
 * @param {string} dataKey      - The specific key within the collection item (row) object, representing a column name.
 *                                Each key in the item (row) object corresponds to a collection column.
 *
 * @example createReference('collectionId', 'dataId', 'username') => '::collectionId::dataId::username'
 */
const createReference = (collectionId: string, dataId: string, dataKey: string) => {
	return `${DELIMITER}${collectionId}${DELIMITER}${dataId}${DELIMITER}${dataKey}`;
};

const isCollectionReference = (value: unknown): value is string => {
	return typeof value === 'string' && value.match(referencePattern) !== null;
};

const parseReference = (value: string, logError = true) => {
	if (typeof value !== 'string') {
		if (logError) console.error('Invalid reference format');
		return null;
	}
	const match = value.match(referencePattern);

	if (!match) {
		if (logError) console.error('Invalid reference format');
		return null;
	}

	const [, collectionId, dataId, dataKey] = match;

	return {
		collectionId,
		dataId,
		dataKey,
	};
};

const replaceCollectionReference = (value: string, cmsItems: CmsModel['items']) => {
	const parsedReference = parseReference(value);
	if (!parsedReference) {
		return value;
	}
	const { collectionId, dataId, dataKey } = parsedReference;
	const dataRow = cmsItems?.[collectionId]?.find((o, i) => (dataId === DATA_ANY ? i === 0 : o.id === dataId));
	return dataRow && dataKey in dataRow ? dataRow[dataKey] : value;
};

const parseCollectionLimit = <T>(limit: T, paginationType: CollectionPaginationType): number | null => {
	if (typeof limit === 'string') {
		return parseInt(limit, 10);
	}
	if (typeof limit === 'number') {
		return limit;
	}
	return paginationType === CollectionPaginationType.none ? defaultProps[UNIFORM_PROPS.collectionLimit] : null;
};

const defaultProps = {
	// limit to 100 max by default if pagination is not set, otherwise limit is value from 1 to Infinity
	[UNIFORM_PROPS.collectionLimit]: 100,
	[UNIFORM_PROPS.collectionPageSize]: 7,
	[UNIFORM_PROPS.collectionPagination]: CollectionPaginationType.none,
	[UNIFORM_PROPS.collectionPaginationTriggers]: { prev: '', next: '' } as CollectionPaginationTriggersProp,
};

export default {
	parseReference,
	isCollectionReference,
	replaceCollectionReference,
	createReference,
	parseCollectionLimit,
	DATA_ANY,
	defaultProps,
};
