import { IframeTunnel, IFRAME_ACTIONS } from 'utils/iframe-tunnel';
import type { BBModel, CardData, EditableStateInfo, StorySettingsType, StorySymbols } from 'types/story';
import type { SetEditorModePayload } from 'admin/actions/card-editor/set-editor-mode';
import type { LottiePlaybackPayload } from 'client/components/common/BuildingBlocks/Lottie/utils';
import type { CmsModel } from 'types/cms';

export type TransmitStoryElementsToClient = (props: {
	editableState: EditableStateInfo;
	elements: BBModel[];
	settings?: StorySettingsType;
	symbols?: StorySymbols;
	cmsModel?: CmsModel;
}) => void;

export type TransmitCardDataToClient = (props: {
	editableState: EditableStateInfo;
	data?: CardData;
	settings?: StorySettingsType;
	symbols?: StorySymbols;
	cmsModel?: CmsModel;
}) => void;

export type InitiateElementSelectInClient = (props: {
	editableState: EditableStateInfo;
	id: string;
	scrollIntoView?: boolean;
	forceSelectionId?: string;
	eventOptions?: {
		bubbles?: MouseEvent['bubbles'];
		shiftKey?: MouseEvent['shiftKey'];
		ctrlKey?: MouseEvent['ctrlKey'];
		metaKey?: MouseEvent['metaKey'];
	};
}) => void;

class CardEditorIframeTransmitter {
	private IframeTunnel = new IframeTunnel({ id: 'CardEditor:IframeTransmitterProvider' });

	forceSelectionHintUpdate() {
		this.IframeTunnel.messageTransmitter({
			target: IframeTunnel.targets.client,
			action: IFRAME_ACTIONS.FORCE_SELECTION_HINT_UPDATE,
			payload: undefined,
		});
	}

	/**
	 * Transmit to application client side updated story elements.
	 * Receiver is here - "routes/client/components/pages/Story/IframeMessageReceiver.js"
	 */
	transmitStoryElementsToClient: TransmitStoryElementsToClient = payload => {
		this.IframeTunnel.messageTransmitter({
			target: IframeTunnel.targets.client,
			action: IFRAME_ACTIONS.UPDATE_CLIENT_STORY_ELEMENTS,
			payload,
		});
	};

	/**
	 * Transmit to client iframe request to select some element by id (or deselect if no "id" provided).
	 * This will cause update UI in client to show SelectionHint and then
	 * client have to transmit to admin panel latest computed data + inheritance of the selected element
	 * to update all dependent components in admin (InspectorContent.js, LayersTree.js).
	 *
	 * @see client/components/.../IframeMessageReceiver.js
	 */
	initiateElementSelectInClient: InitiateElementSelectInClient = ({ scrollIntoView = true, ...props }) => {
		this.IframeTunnel.messageTransmitter({
			target: IframeTunnel.targets.client,
			action: IFRAME_ACTIONS.INITIATE_SELECT_EDITOR_ELEMENT,
			payload: { ...props, scrollIntoView },
		});
	};

	/**
	 * Transmit to application client side updated card object.
	 * Receiver is here - "routes/client/components/pages/Story/IframeMessageReceiver.js"
	 */
	transmitCardDataToClient: TransmitCardDataToClient = payload => {
		this.IframeTunnel.messageTransmitter({
			target: IframeTunnel.targets.client,
			action: IFRAME_ACTIONS.UPDATE_CLIENT_CARD_DATA,
			payload,
		});
	};

	transmitEditorModeToClient = (payload: SetEditorModePayload) => {
		this.IframeTunnel.messageTransmitter({
			target: IframeTunnel.targets.client,
			action: IFRAME_ACTIONS.SET_EDITOR_MODE,
			payload,
		});
	};

	transmitLottiePlayback = (payload: LottiePlaybackPayload) => {
		this.IframeTunnel.messageTransmitter({
			target: IframeTunnel.targets.client,
			action: IFRAME_ACTIONS.LOTTIE_PLAYBACK,
			payload,
		});
	};
}

export default new CardEditorIframeTransmitter();
