import React from 'react';
import { round } from 'lodash';
import { Collapse } from 'antd';
import Text from 'admin/components/common/Text';
import { AIImageSize } from 'admin/actions/story/ai/types';
import Limits from 'admin/components/pages/Settings/Limits';
import useAIUsage from './use-ai-usage';
import css from './Plans.scss';

const getImageDimensionLabel = (v: string) => {
	switch (v) {
		case AIImageSize.portrait:
			return 'Portrait';
		case AIImageSize.landscape:
			return 'Landscape';
		case AIImageSize.square:
		default:
			return 'Square';
	}
};

export const formattedValue = (value: number, unit?: '$' | 'USD') => {
	const num = round(value, 2);
	return unit ? `${['$', 'USD'].includes(unit) ? num.toFixed(2) : num}${unit === 'USD' ? ' ' : ''}${unit}` : num;
};

type Props = {
	children: React.ReactNode;
};

const AIUsage: React.FC<Props> = props => {
	const aiUsage = useAIUsage();

	if (!aiUsage.formattedData) {
		return null;
	}

	const { formattedData, max } = aiUsage;
	const hasMax = typeof max === 'number';

	return (
		<div className={css.planInfo}>
			<div className={css.header}>
				<div className={css.row}>
					<Text weight={Text.weight.semibold} size={Text.size.subheading}>
						AI Usage
					</Text>
					<Text weight={Text.weight.semibold} size={Text.size.subheading}>
						{formattedValue(formattedData.total, '$')}
					</Text>
				</div>
				<div className={css.row}>
					<Text size={Text.size.label} style={{ marginLeft: 'auto' }}>
						{hasMax ? `/${formattedValue(max, '$')} Limit` : 'Unlimited'}
					</Text>
				</div>
			</div>
			<div className={css.content}>
				<Collapse
					className={css.collapse}
					ghost
					items={[
						{
							key: '0',
							collapsible: 'disabled',
							label: (
								<Limits
									title="Monthly usage"
									titleSize={Text.size.article}
									valueSize={Text.size.paragraph}
									current={
										hasMax
											? formattedValue(formattedData.total)
											: formattedValue(formattedData.total, '$')
									}
									max={max}
									format={hasMax ? 'percentage' : 'value'}
									progress
									progressColor="progress"
									withPadding
									className={css.aiLimit}
									dataClassName={css.collapseData}
								/>
							),
						},
						{
							key: '1',
							label: (
								<Limits
									title="Images"
									current={formattedValue(formattedData.images.total, '$')}
									format="value"
									titleSize={Text.size.paragraph}
									className={css.aiLimit}
									dataClassName={css.collapseData}
								/>
							),
							children: formattedData.images.items.map(item => (
								<Limits
									key={`item-${item.type}-${item.dimension}`}
									title={getImageDimensionLabel(item.dimension)}
									current={formattedValue(item.usage, '$')}
									format="value"
									titleSize={Text.size.paragraph}
									titleWeight={Text.weight.normal}
									className={css.aiLimit}
									dataClassName={css.collapseData}
								/>
							)),
						},
						{
							key: '2',
							collapsible: 'disabled',
							label: (
								<Limits
									title="Text"
									current={formattedValue(formattedData.text.total, '$')}
									format="value"
									titleSize={Text.size.paragraph}
									className={css.aiLimit}
									dataClassName={css.collapseData}
								/>
							),
						},
					]}
				/>
			</div>
			{props.children}
		</div>
	);
};

export default AIUsage;
